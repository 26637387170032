import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/integrations-slack.css"
//import components
import GetStartedBanner from "../../components/get-started-footer"

const TeamsIntegrationPage = ({ data }) => (
  <Layout>
    <SEO
      title="Connect Microsoft Teams to your Odown monitors"
      description="Receive real-time alerts from Odown to your preferred Teams channels."
      pathname={`/integrations/microsoftteams/`}
      image={{
        src: "/general-image-og--odown_integration_Teams.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid}/>
    <Description />
    <CombinedSections />
    <CombinedSections2 />
    <Howtouse />
    <SuperchargeYourIncidentResponseSection />
    <BuiltforTeamsofAllSizes />
    <SecureandReliable />
    <TryOdownSection />
    <ClosingSection />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = ({ imageData }) => {
    const [getStartedButton, setGetStartedButton] = useState({
      name: "Get started for free",
      link: "https://app.odown.io/signup",
    });
  
    return (
      <div className="u-features slack-hero">
        <div className="flex-container">
          <div className="text-section">
            <h1 className="custom-heading-title">
            Stay on Top of Website Odown time with Odown's Microsoft Teams Integration
            </h1>
            <div className="u-features__heading-btn center-button">
              <OutboundLink
                className="custom-btn custom-btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blank"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
          <div className="image-section">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={imageData}
              alt="Integration - Teams"
            />
          </div>
        </div>
      </div>
    );
  };  
const Description = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <p className="u-main-feature_tc-desc2">
    Website Odowntime is a dangerous problem that has more impact 
    than the normal problems that are used for many different 
    business works. Currently, Odown's Microsoft Teams Integration 
    can do most of the work for example a good integration can give 
    high quality monitoring, with the help of varied alerts you can 
    do various tasks like instant notification, quick response, and 
    also make beautiful monitoring system of your favorite platform. 
    These integrations make your monitoring very easy by giving you 
    varied possibilities which help to complete your response tasks. 
       </p>
     </div>
  </section>
)

const CombinedSections = ({ imageData }) => (
    <div className="sections-container">
        <h2 className="centered-heading">Why Connect Odown with Microsoft Teams?</h2>
        <div className="real-time-section">
            <RealTimeNotificationSection imageData={imageData} />
        </div>
        <div className="streamlined-section">
            <StreamlinedIncidentManagementSection imageData={imageData} />
        </div>
    </div>
);

  const RealTimeNotificationSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Real-Time Notifications</h3>
              <p className="u-main-feature_tc-desc">
              Checking dashboard manually is very problematic during monitoring time. 
              Today, such notifications need proper setup in Microsoft Teams, and 
              initially they need good configuration and now proper alert management too. 
              These alerts convey many things such as, instant updates and also natural 
              elements like quick response which symbolize good monitoring.
              </p>
             </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const StreamlinedIncidentManagementSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Streamlined Incident Management</h3>
              <p className="u-main-feature_tc-desc">
              Wrong communication is very dangerous during Odowntime periods. 
              Different methods are used and with the help of proper Teams channels 
              they make very organized incident management that is of great use for teams. 
              Also people are benefited from this as they take proper discussions and use 
              proper tools to complete resolution. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const CombinedSections2 = () => (
    <div className="sections-container">
        <div className="real-time-section">
            <CustomizableForYourWorkflowSection />
        </div>
        <div className="streamlined-section">
            <A360ViewOfPerformanceSection />
        </div>
    </div>
);

const CustomizableForYourWorkflowSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Customizable for Your Workflow</h3>
              <p className="u-main-feature_tc-desc">
              Many teams need different alert settings that is important for work organization. 
              These settings need many things such as:
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Channel-Specific Alerts : </strong>
                Setting channels adds many benefits to alert management. 
                Presently alerts can go to different teams and departments, 
                with the help of different methods like #devops or #general 
                you can do various notifications safely.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Alert Thresholds : </strong> 
                Long time without proper thresholds can cause alert fatigue. 
                Today you find proper settings like urgent and normal alerts 
                and as such can come in handy in the current monitoring culture.
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Adjustable Frequency: </strong> 
                Bad frequency settings can make alerts overwhelming that is 
                dangerous for productivity. These settings many depicts 
                different patterns and beautiful organization containing 
                stories of the monitoring and about people's preferences and needs.                
                </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const A360ViewOfPerformanceSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">A 360° View of Your Website's Performance</h3>
              <p className="u-main-feature_tc-desc">
              Website monitoring is a good technique that has more features than the 
              normal uptime checks that are used for many different works. Presently 
              monitoring can do most of the work for example:
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Performance Trends </strong>
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Geographical Outage Alerts </strong> 
              </p>
              <p className="u-main-feature_tc-desc indented">
                &#10004; <strong>Planned Maintenance Notifications </strong> 
              </p>
              <p className="u-main-feature_tc-desc">
              These features make your monitoring very comprehensive by 
              giving you diverse insights which help to complete your 
              website management tasks.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const Howtouse = () => {
    return (
      <section className="u-main-feature integrations-slack-section" style={{ padding: '30px 20px' }}>
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="content-title1">Simple Setup, Rapid Results</h2>
              <p className="u-main-feature_tc-desc2">
              Odown's Microsoft Teams Integration is an upgraded system that 
              has more efficiency than the normal systems that are used for 
              many different tasks. Currently, this integration can do most 
              of the work for example getting real-time alerts where your 
              team already works together.
              </p>
    
              <p className="use-desc2">
              Open Integrations :
              Log in to Odown dashboard and navigate to "Integrations" section.
              </p>
              <p className="use-desc2">
              Select Microsoft Teams :               
              Give permission to Odown for posting in your chosen Teams channels.
              </p>
              <p className="use-desc2">
              Fine-Tune Your Alerts :
              Select teams or channels for alerts, set severity levels, and decide alert frequency.
              </p>
              <p className="use-desc2">
              Start Monitoring :
              Get automatic, real-time notifications in Teams that is of great use for Odowntime tracking.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const SuperchargeYourIncidentResponseSection = () => {
    const features = [
      {
        title: "Centralized Visibility",
        description: " Team members see same alert at same time that is of great use for quick action.",
      },
      {
        title: "Less Context Switching",
        description: "No need to use different platforms as you can solve issues in Teams directly.",
      },
      {
        title: "Better Team Alignment",
        description: "No need to use different platforms as you can solve issues in Teams directly.",
      },
    ];
  
    return (
      <section className="u-advanced-features" style={{ padding: '30px 20px' }}>
        <div className="container">
          <h2 className="content-title1">Supercharge Your Incident Response</h2>
          <div className="u-advanced-features__items">
          <p className="u-main-feature_tc-desc2">
          Microsoft Teams integration with Odown makes response efforts very 
          organized that has more benefits than normal systems. These benefits include many things such as:
       </p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <div className="u-a-features__item-wrapper">
                    <div className="u-a-features__item--title">
                      {feature.title}
                    </div>
                    <div className="u-a-features__item--desc">
                      {feature.description}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  };
 
  const BuiltforTeamsofAllSizes = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Perfect for Teams of All Sizes</h2>
    <p className="u-main-feature_tc-desc2">
    Different size teams can use this integration that is very beneficial 
    for both small and big organizations. Today, such system gives proper 
    setup for small teams, and initially they need good configuration and 
    now proper channel management too for big organizations. 
       </p>
     </div>
  </section>
)


const SecureandReliable = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Secure & Reliable</h2>
    <p className="u-main-feature_tc-desc2">
    Security in integration increase trust and safety. 
    These features many depicts different patterns and 
    beautiful security measures containing stories of 
    the data protection and about people's information safety.    
       </p>
     </div>
  </section>
)


const TryOdownSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Try Odown's Microsoft Teams Integration Today</h2>
    <p className="u-main-feature_tc-desc2">
    Integration Presently can do most of the work for example getting real-time alerts, 
    with the help of different features you can do various tasks like minimizing Odowntime 
    and keeping customers happy.
       </p>
     </div>
  </section>
)

const ClosingSection = () => (
    <div className="sections-container-closing" style={{ padding: '30px 20px' }}>
        <h2 className="centered-heading">
        Questions or Need Help?
        </h2>
        <p className="u-main-feature_tc-desc2">
        Support team is very helpful during setup time. Different methods are used 
        and with the help of proper guidance they make very good experience that 
        is highly beneficial for users.
       </p>
    </div>
);


export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "teams_icon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    
  }
`

export default TeamsIntegrationPage